.wrapper {
  display: inline-block;
  margin-bottom: 20px;
}

.multiChoiceTestContainer {
  margin-bottom: 18px;
}

.multiChoiceTestContainer button {
  display: flex;
  padding: 16px;
  padding-right: 24px;
  border-radius: 24px;
  margin-top: 36px;
  font-size: 16px;
  background-color: rgb(42 154 218 / 9%);
  transition: background-color 0.3s ease-out;
  cursor: default;
}

.multiChoiceTestContainer button:hover {
  background-color: rgb(42 154 218 / 18%);
}

.multiChoiceTestContainer button + button {
  margin-top: 12px;
}

.multiChoiceTestContainer span {
  margin-left: 0px;
}

.container {
  display: grid;
  grid-template-columns: repeat(3, 200px);
  gap: 20px;
}

.btn {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  font-size: 12px;
  text-align: start;
}
.btn.disable {
  color: inherit;
  cursor: default;
}

.btn::before {
  flex: 0 0 1em;
  display: block;
  content: "";
  width: 1em;
  height: 1em;
  border-radius: 100%;
  border: 1px solid var(--color-accent);
}

.btn.active::before {
  background-color: var(--color-accent);
}

.disable .btn {
  cursor: default;
}

.answerWrap,
.answerWrapTask {
  max-width: 600px;
  display: grid;
  grid-template-columns: 200px auto;
  row-gap: 10px;
}

.answerWrapTask {
  margin-bottom: 20px;
}



.capsuleLeft,
.capsuleRight {
  width: auto;
  box-shadow: var(--box-shadow-light);
  font-size: 12px;
  min-height: 33px;
  padding-right: 20px;
  padding-left: 20px;
  min-width: 150px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.capsuleLeft {
  position: relative;
  z-index: 2;
  background-color: var(--color-accent);
  color: white;
  font-weight: 600;
  border-radius: 16px 0 0 16px;
}

.capsuleRight {
  position: relative;
  z-index: 1;
  background-color: white;
  font-weight: 500;
  border-radius: 0 16px 16px 0;
}

.red {
  background-color: var(--color-accent-first);
}

@media (max-width: 768px) {

  .container {
    grid-template-columns: auto;
  }

  .answerWrap,
  .answerWrapTask {
    max-width: 200px;
    display: flex;
    flex-direction: column;
    row-gap: 0;
  }

  .capsuleLeft,
  .capsuleRight {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 5px;
  }

  .capsuleRight {
    margin-bottom: 10px;
  }

  .multiChoiceTestContainer button {
    display: flex;
    padding: 12px;
    padding-right: 22px;
    border-radius: 18px;
    margin-top: 24px;
    font-size: 12px;
    background-color: rgb(42 154 218 / 9%);
    transition: background-color 0.3s ease-out;
  }

  .multiChoiceTestContainer button + button {
    margin-top: 6px;
  }

  .multiChoiceTestContainer span {
    margin-left: 4px;
  }
}

.cgecingOtv{
  display: flex;
  font-style: 15px;
  flex-wrap: wrap;
  gap:10px;
  margin-bottom: 8px;
}
.cgecingOtv *{
  font-size: 15px;
}
.estimation {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-top: 30px;
}

.booble {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 55px;
  min-width: 55px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: var(--color-white);
  border-radius: 16px;
  font-weight: 700;
  font-size: 14px;
  box-shadow: var(--box-shadow);
  text-align: center;
}
