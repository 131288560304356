.subTitle{
    font-size: 0.875rem;
    margin-top: 1rem;
}
.titleAndSubTitle{
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
}
.blocks{
    margin-top: 1.25rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

body .button{
    border-radius: 1.3rem;
    background: #F9EDFF;
    font-weight: 600;
    font-size: 0.88rem;
    padding: 0.625rem 1.5rem;
    margin-top: 2.5rem;
    border: none;
}
.authorized{
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 33px;
    text-align: center;
}
.page{
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: calc(100% - 60px);
}
.notAuth{
    padding: 0px 30px;
    padding-bottom: 30px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}
.wrapperCompletion{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 1.5rem;
}
.completionTitle{
    font-size: 24px;
    text-align: center;
}
.completionTitle *{
    font-size: 24px;
    text-align: center;
}
.completionSubTitle{
    font-size: 18px;
    text-align: center;
}
.completionSubTitle *{
    font-size: 18px;
    text-align: center;
}
.completionImg{
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}
@media (max-width:1280px) {
    .subTitle{
        font-size: calc(0.875rem * 1.3);
        margin-top: calc(1rem * 1.3);
    }
    .titleAndSubTitle{
        margin-top: calc(2.5rem * 1.3);
    }
    .blocks{
        margin-top: calc(1.25rem * 1.3);
        gap: calc(1.25rem * 1.3);
    }
    body .button{
        border-radius: calc(1.3rem * 1.3);
        font-size: calc(0.88rem * 1.3);
        padding: calc(0.625rem * 1.3) calc(1.5rem * 1.3);
        margin-top: calc(2.5rem * 1.3);
    }
    .wrapperCompletion{
        margin-top: calc(1.5rem * 1.3);
        gap: calc(1.5rem * 1.3);
    }
}
@media (max-width:992px) {
    .subTitle{
        font-size: calc(0.875rem * 1.6);
        margin-top: calc(1rem * 1.6);
    }
    .titleAndSubTitle{
        margin-top: calc(2.5rem * 1.6);
    }
    .blocks{
        margin-top: calc(1.25rem * 1.6);
        gap: calc(1.25rem * 1.6);
    }
    body .button{
        border-radius: calc(1.3rem * 1.6);
        font-size: calc(0.88rem * 1.6);
        padding: calc(0.625rem * 1.6) calc(1.5rem * 1.6);
        margin-top: calc(2.5rem * 1.6);
    }
    .page{
        max-width: 100%;
        padding-bottom: 80px;
    }
    .wrapperCompletion{
        margin-top: calc(1.5rem * 1.6);
        gap: calc(1.5rem * 1.6);
    }
}
@media (max-width:767px) {
    .subTitle{
        font-size: calc(0.875rem * 3.3);
        margin-top: calc(1rem * 3.3);
    }
    .titleAndSubTitle{
        margin-top: calc(2.5rem * 3.3);
    }
    .blocks{
        margin-top: calc(1.25rem * 3.3);
        gap: calc(1.25rem * 3.3);
    }
    body .button{
        border-radius: calc(1.3rem * 3.3);
        font-size: calc(0.88rem * 3.3);
        padding: calc(0.625rem * 3.3) calc(1.5rem * 3.3);
        margin-top: calc(2.5rem * 3.3);
    }
    .notAuth{
        padding: 0px 15px;
        padding-bottom: 15px;
    }
    .wrapperCompletion{
        margin-top: calc(1.5rem * 3.3);
        gap: calc(1.5rem * 3.3);
    }
}
@media(max-width: 700px) {
    .page{
        gap: 23px;
    }
    .authorized{
        font-size: 27px;
    } 
}